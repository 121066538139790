@font-face {
    font-family: 'Hotpoint-Quicksand';
    src: url('../../../fonts/hotpoint/hotpoint-quicksand/Hotpoint-Regular.woff2') format('woff2');
    src: url('../../../fonts/hotpoint/hotpoint-quicksand/Hotpoint-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Hotpoint-Quicksand-Bold';
    src: url('../../../fonts/hotpoint/hotpoint-quicksand/Hotpoint-Bold.woff2') format('woff2');
    src: url('../../../fonts/hotpoint/hotpoint-quicksand/Hotpoint-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Hotpoint-Script';
    src: url('../../../fonts/hotpoint/hotpoint-script/HotpointScript-Script.woff2') format('woff2');
    src: url('../../../fonts/hotpoint/hotpoint-script/HotpointScript-Script.woff') format('woff');
}

